import { Component, input, OnInit, output } from '@angular/core';
import {
  Appointment,
  AppointmentAcceptanceShortInfo,
  AppointmentInvitations,
  AppointmentInvitationState,
  AppointmentPropertyShortInfo,
  AppointmentsBundles,
  PropertyApplicationStatus
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { NgClass } from '@angular/common';
import {
  AddressPipe,
  ButtonComponent,
  ContextMenuComponent,
  ContextMenuItemComponent,
  DateTimePipe,
  HintComponent,
  ImageSizePipe
} from '@ui/legacy-lib';
import { CustomerInfoLegacyComponent } from '../customer-info-legacy/customer-info-legacy.component';

@Component({
  selector: 'app-appointments-property-item',
  templateUrl: './appointments-property-item.component.html',
  styleUrls: ['./appointments-property-item.component.scss'],

  imports: [
    CustomerInfoLegacyComponent,

    NgClass,
    SvgIconComponent,
    TranslateModule,
    AddressPipe,
    DateTimePipe,
    ImageSizePipe,
    ContextMenuComponent,
    ContextMenuItemComponent,
    ButtonComponent,
    HintComponent
  ]
})
export class AppointmentsPropertyItemComponent implements OnInit {
  readonly applicationId = input<string>(undefined);
  readonly applicationStatus = input<string>(undefined);
  readonly property = input<AppointmentPropertyShortInfo>(undefined);
  readonly appointments = input<Appointment[]>(undefined);
  readonly appointmentAcceptances =
    input<AppointmentAcceptanceShortInfo[]>(undefined);
  readonly appointmentInvitations = input<AppointmentInvitations[]>(undefined);
  readonly appointmentsItemLoading = input(false);
  readonly showCustomerInfo = input(true);
  readonly selectAppointment = output<AppointmentsBundles>();
  readonly cancelAppointment = output<string>();
  readonly exportToCalendar = output<{
    property: AppointmentPropertyShortInfo;
    appointment: Appointment;
  }>();

  public activeAppointment: Appointment;
  public activeAppointmentAcceptance: AppointmentAcceptanceShortInfo;

  public appointmentState = AppointmentInvitationState;

  get picture() {
    const property = this.property();
    return property && property.image;
  }

  get appointmentsNotDeclined() {
    return (
      this.applicationStatus() !==
      PropertyApplicationStatus.ALL_APPOINTMENTS_DECLINED
    );
  }

  public getActiveAppointment(): Appointment {
    if (this.appointmentAcceptances()?.length === 0) return;
    this.activeAppointment = this.appointments()
      .filter(
        appointment => appointment.state === AppointmentInvitationState.ACTIVE
      )
      .find(appointment => {
        this.activeAppointmentAcceptance = this.appointmentAcceptances()
          .filter(
            appointmentAcceptances =>
              appointmentAcceptances.state === AppointmentInvitationState.ACTIVE
          )
          .find(
            appointmentAcceptances =>
              appointmentAcceptances.appointmentId === appointment.id
          );
        return !!this.activeAppointmentAcceptance;
      });
  }

  ngOnInit(): void {
    this.getActiveAppointment();
  }

  public onExportToCalendar() {
    if (!this.activeAppointment) return;
    this.exportToCalendar.emit({
      property: this.property() as any,
      appointment: this.activeAppointment
    });
  }

  public onSelectAppointment() {
    this.selectAppointment.emit({
      applicationId: this.applicationId(),
      applicationStatus: this.applicationStatus(),
      property: this.property(),
      appointments: this.appointments(),
      appointmentAcceptances: this.appointmentAcceptances(),
      appointmentInvitations: this.appointmentInvitations()
    });
  }

  public onCancelViewing() {
    if (!this.activeAppointmentAcceptance) return;
    this.cancelAppointment.emit(this.activeAppointmentAcceptance.id);
  }
}
